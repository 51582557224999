var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l(([
      {
        label0: '经纪人注册数量',
        value0: _vm.columnAmount(_vm.brokerStatisticInfo.registerCount, {
          fixed: 0,
        }),

        label1: '活跃度',
        value1: `${_vm.columnAmount(_vm.brokerStatisticInfo.activity * 100)}%`,

        label2: '活跃数',
        value2: `${_vm.columnAmount(_vm.brokerStatisticInfo.activelyRegisterCount, {
          fixed: 0,
        })}`,

        column0: {
          label: '昨日新增',
          value: _vm.columnAmount(_vm.brokerStatisticInfo.yesterdayCount, {
            fixed: 0,
          }),
        },
        column1: {
          label: '本周新增',
          value: _vm.columnAmount(_vm.brokerStatisticInfo.weeklyCount, {
            fixed: 0,
          }),
        },
        column2: {
          label: '本月新增',
          value: _vm.columnAmount(_vm.brokerStatisticInfo.monthlyCount, {
            fixed: 0,
          }),
        },
      },

      {
        label0: '合伙人注册数量',
        value0: _vm.columnAmount(_vm.leaderStatisticInfo.registerCount, {
          fixed: 0,
        }),

        column0: {
          label: '昨日新增',
          value: _vm.columnAmount(_vm.leaderStatisticInfo.yesterdayCount, {
            fixed: 0,
          }),
        },
        column1: {
          label: '本周新增',
          value: _vm.columnAmount(_vm.leaderStatisticInfo.weeklyCount, {
            fixed: 0,
          }),
        },
        column2: {
          label: '本月新增',
          value: _vm.columnAmount(_vm.leaderStatisticInfo.monthlyCount, {
            fixed: 0,
          }),
        },
      },

      //
    ]),function(item,index){return _c('div',{key:index,staticClass:"bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"},[_c('div',{staticClass:"dis_flex"},[_c('div',{staticClass:"flex_1"},[_c('div',{staticClass:"font_16 font_bold"},[_vm._v(_vm._s(item.label0))]),_c('div',{staticClass:"font_24 font_bold m_t_8"},[_vm._v(_vm._s(item.value0))])]),(item.label1)?_c('div',{staticClass:"m_0_16 m_t_4"},[_c('div',{staticClass:"font_14 font_bold"},[_vm._v(_vm._s(item.label1))]),_c('div',{staticClass:"font_18 font_bold m_t_8"},[_vm._v(_vm._s(item.value1))])]):_vm._e(),(item.label2)?_c('div',{staticClass:"m_0_16 m_t_4"},[_c('div',{staticClass:"font_14 font_bold"},[_vm._v(_vm._s(item.label2))]),_c('div',{staticClass:"font_18 font_bold m_t_8"},[_vm._v(_vm._s(item.value2))])]):_vm._e()]),_c('div',{staticClass:"dis_flex m_t_8"},[_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column0.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column0.value))])]),_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column1.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column1.value))])]),_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column2.label))]),_c('div',{staticClass:"col_2396F5 font_bold m_l_8"},[_vm._v(_vm._s(item.column2.value))])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }